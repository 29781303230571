/* Enable smooth scrolling */
html {
    scroll-behavior: smooth;
  }

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
    background-color: #f0f0f0;
  }
  
  .spinner {
    border: 8px solid #f3f3f3; /* Light gray */
    border-top: 8px solid #3F9820; /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
    